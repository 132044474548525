/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { useEffect, useState } from 'react'
import { Button, FlexBox, Icon, Text } from 'vspry-style-components'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

// Components
import Logo from 'components/Logo'
import PublicPageContainer from 'components/style-elements/containers/PublicPageContainer'
import VspryDivider from 'components/style-elements/VspryDivider'
import theme from 'styles/theme'

// utils
import { useAppConfig } from 'context/appConfigContext'
import { useLocale } from 'context/localeContext'
import { useAuth } from 'context/authContext'

import FetchingPage from './FetchingPage'

export default function LandingPage() {
    const { translate } = useLocale()
    const { identity } = useAuth()
    const navigate = useNavigate()
    const location = useLocation()
    const { appConfig } = useAppConfig()
    const [searchParams] = useSearchParams()
    const [redirect, setRedirect] = useState(null as null | string)
    const [supported, setSupported] = useState(false)

    useEffect(() => {
        const redirectP = searchParams.get('redirect')
        if (redirectP) setRedirect(decodeURIComponent(redirectP))

        if (identity) navigate(redirectP ? decodeURIComponent(redirectP) : '/home')
        else if (!appConfig.consumers_enabled && appConfig.merchants_enabled)
            navigate(`/signin?type=email&redirect=${encodeURIComponent(redirectP ?? appConfig.merchant_signin_redirect ?? '/home')}&tenant=merchant`)
    }, [identity, location, appConfig])

    const showSignup =
        (redirect?.includes('referral') || !appConfig.referral_signup_only_enabled) && (appConfig.consumers_enabled || appConfig.external_register)

    if (identity) return <FetchingPage />

    useEffect(() => {
        if ('BarcodeDetector' in window) setSupported(true)
    }, [])

    return (
        <PublicPageContainer>
            <Logo />
            <FlexBox $column $align gap='small' $fitted>
                <FlexBox $column gap='small' width='75%'>
                    <Button
                        id='email-sign-in'
                        onClick={() =>
                            navigate(
                                `/signin?redirect=${encodeURIComponent(redirect ?? appConfig.consumer_signin_redirect ?? '/home')}&tenant=consumer`
                                // eslint-disable-next-line react/jsx-indent
                            )
                        }
                    >
                        {translate('buttons.signIn')}
                    </Button>
                    {window.configuration['EXT_SSO_CLIENT_ID'] && window.configuration['EXT_SSO_CLIENT_SECRET'] && (
                        <Button id='ext-sign-in' onClick={() => navigate(`/signin?type=ext&redirect=${encodeURIComponent(redirect ?? '/home')}`)}>
                            {translate('buttons.signInExt')}
                        </Button>
                    )}
                </FlexBox>
                <VspryDivider>{translate('pages.landingPage.dontHaveAccount')}</VspryDivider>
                {showSignup ? (
                    <Button
                        id='new-user-signup'
                        color='highlight'
                        onClick={() =>
                            navigate(
                                `/signup?redirect=${encodeURIComponent(redirect ?? appConfig.consumer_signin_redirect ?? '/home')}&tenant=consumer`
                                // eslint-disable-next-line react/jsx-indent
                            )
                        }
                        width='75%'
                    >
                        {translate('pages.landingPage.openConsumerAccount')}
                    </Button>
                ) : (
                    <>
                        <Text size='small' margin='no'>
                            {translate('pages.landingPage.askPractitioner')}
                        </Text>
                        {supported && (
                            <FlexBox $align margin='small' gap='xSmall' onClick={() => navigate('/qrscanner')}>
                                <Icon name='camera' customColor={theme.accent} />
                                <Text size='xSmall' $left $bold>
                                    {translate('pages.landingPage.scanQRCode')}
                                </Text>
                            </FlexBox>
                        )}
                    </>
                )}
            </FlexBox>
            <FlexBox $column gap='small' width='75%'>
                {appConfig.merchants_enabled && (
                    <Button
                        id='merchant-sign-in'
                        onClick={() =>
                            navigate(
                                `/signin?type=email&redirect=${encodeURIComponent(
                                    redirect ?? appConfig.merchant_signin_redirect ?? '/home'
                                )}&tenant=merchant`
                                // eslint-disable-next-line react/jsx-indent
                            )
                        }
                        color='disabledButtonBG'
                    >
                        {translate('buttons.merchantSignin')}
                    </Button>
                )}
            </FlexBox>
        </PublicPageContainer>
    )
}
