/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { outline, Scanner } from '@yudiel/react-qr-scanner'
import { useRef } from 'react'

type QRScannerProps = {
    onScan: (code: string) => void
}
export default function QRScanner({ onScan }: QRScannerProps) {
    const timeout = useRef<NodeJS.Timeout | null>()

    return (
        <Scanner
            onScan={(r) => {
                if (!timeout.current) {
                    console.debug(r)
                    onScan(r[0].rawValue)
                    timeout.current = setTimeout(() => {
                        timeout.current = null
                    }, 5000)
                }
            }}
            styles={{ video: { aspectRatio: '1' } }}
            components={{ finder: false, tracker: outline }}
            formats={['qr_code']}
            allowMultiple
            scanDelay={5000}
        />
    )
}
